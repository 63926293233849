<template>
  <div class="dashboard-container" v-loading="loading">
    <el-row :gutter="20" class="item-row">
      <el-col :span="6">
        <div class="item_list">
          <div>
            <span>{{info.member_num?info.member_num:0}}</span>
            <span>总用户数量</span>
          </div>
          <img src="/img/dd-y.png" />
        </div>
      </el-col>
      <el-col :span="6">
        <div class="item_list">
          <div>
            <span>{{info.collection_shop?info.collection_shop:0}}</span>
            <span>关注店铺用户数</span>
          </div>
          <img src="/img/dd-y.png" />
        </div>
      </el-col>
      <el-col :span="6">
        <div class="item_list">
          <div>
            <span>{{info.buy_goods_member?info.buy_goods_member:0}}</span>
            <span>已下单用户数</span>
          </div>
          <img src="/img/dd-y.png" />
        </div>
      </el-col>
      <el-col :span="6">
        <div class="item_list">
          <div>
            <span>{{info.collection_goods?info.collection_goods:0}}</span>
            <span>收藏店铺活动的用户</span>
          </div>
          <img src="/img/dd-y.png" />
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-bottom: 10px">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix echart-title">
            <p>客户群体分析</p>
            <p>
              <el-button size="small" @click="funcUserType='getUserTypePlatform';handleButtonChangeUserType()" :type="funcUserType=='getUserTypePlatform'?'primary':''">平台访问客户分析</el-button>
              <el-button size="small" @click="funcUserType='getUserTypeOrder';handleButtonChangeUserType()" :type="funcUserType=='getUserTypeOrder'?'primary':''">下单客户分析</el-button>
            </p>
            <chart-timer-picker active-tag="week" :tags="['week', 'month1', 'year1']" @time-change="handleTimeChangeUserType"/>
          </div>
          <div class="echart-alltitle">
            <div :span="24" id="echarts_right_user_type" class="echarts-right"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-bottom: 0">
      <el-col :span="24">
        <el-card>
          <div slot="header" class="clearfix echart-title">
            <p>客户群体分析</p>
            <p>
              <el-button size="small" @click="funcSex='getSexPlatform';funcArea='getAreaPlatform';handleButtonChangeSexArea()" :type="funcSex=='getSexPlatform'?'primary':''">平台访问客户分析</el-button>
              <el-button size="small" @click="funcSex='getSexOrder';funcArea='getAreaOrder';handleButtonChangeSexArea()" :type="funcSex=='getSexOrder'?'primary':''">下单客户分析</el-button>
            </p>
            <p>
              <chart-timer-picker active-tag="week" :tags="['week', 'month1', 'year1']" @time-change="handleTimeChangeSexArea"/>
            </p>
          </div>
          <div class="echart-alltitle">
            <el-row style="width:100%;">
              <el-col :span="12">
                <div id="echarts_right_sex" class="echarts-right"></div>
              </el-col>
              <el-col :span="12">
                <div id="echarts_right_area" class="echarts-right" style="width:700px;"></div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-bottom: 10px">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix echart-title">
            <p>客户来源分析</p>
            <chart-timer-picker active-tag="week" :tags="['week', 'month1', 'year1']" @time-change="handleTimeChangeFrom"/>
          </div>
          <div class="echart-alltitle">
            <div id="echarts_right_from" class="echarts-right"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as API_Home from "@/api/home";
import * as API_Sta from "@/api/$sta.trade.js";
import ChartTimerPicker from '../../statistics/chart-timer-picker';
import echartsOptionsMap from '../../distribution/echartsOptionsMap';
import echartMapChina from '../../distribution/echart-map-china'

export default {
  name: "customer-type-and-distribution",
  components: { ChartTimerPicker },
  data() {
    return {
      loading: true,
      goodsList: null,
      memberList: null,
      /** 基本数据 */
      info: {},
      advancedForm1: {
        times: "",
      },
      // 订单统计
      active: "day",
      activeName: "今日",
      // 退款订单统计
      active2: "day",
      activeName2: "今日",
      advancedForm2: {
        times: "",
      },
      orderA: '',
      orderB: '',
      funcUserType: 'getUserTypePlatform',
      funcSex: 'getSexPlatform',
      funcArea: 'getAreaPlatform',
      userTypeParams: null,
      sexAreaParams: null
    };
  },
  filters: {
    secrecyMobile(mobile) {
      mobile = String(mobile);
      if (!/\d{11}/.test(mobile)) {
        return mobile;
      }
      return mobile.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3");
    },
  },
  created() {
    this.$echarts.registerMap('china', echartMapChina);
    this.loading = true;
    API_Sta.getUser4Block()
      .then((res) => {
        this.info = this.MixinClone(res);
        this.loading = false;
      })
      .catch((_) => {
        this.loading = false;
      });
  },
  activated() {},
  mounted() {
    this.$nextTick(() => {
      this.sesalChartUserType = this.$echarts.init(
        document.getElementById("echarts_right_user_type")
      );
      this.sesalChartSex = this.$echarts.init(
        document.getElementById("echarts_right_sex")
      );
      this.sesalChartArea = this.$echarts.init(
        document.getElementById("echarts_right_area")
      );
      this.sesalChartFrom = this.$echarts.init(
        document.getElementById("echarts_right_from")
      );
    });
  },
  methods: {
    handleTimeChangeUserType({milli, activeName}) {
      this.Get_UserType(milli[0], milli[1], activeName);

      this.userTypeParams = {milli, activeName};
    },
    handleTimeChangeSexArea({milli, activeName}) {
      this.Get_Sex(milli[0], milli[1], activeName);
      this.Get_Area(milli[0], milli[1], activeName);

      this.sexAreaParams = {milli, activeName};
    },
    handleTimeChangeFrom({milli, activeName}) {
      this.Get_From(milli[0], milli[1], activeName);
    },
    handleButtonChangeUserType() {
      const {milli, activeName} = this.userTypeParams;
      this.Get_UserType(milli[0], milli[1], activeName);
    },
    handleButtonChangeSexArea() {
      const {milli, activeName} = this.sexAreaParams;
      this.Get_Sex(milli[0], milli[1], activeName);
      this.Get_Area(milli[0], milli[1], activeName);
    },
    Get_UserType(start, end, name) {
      API_Sta[this.funcUserType]({
        start,
        end
      }).then((res) => {
        this.loading = false;
        const x = res.xAxis;
        const s = res.series;
        const option = {
          backgroundColor: "#fff", // 背景色
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: x,
          },
          yAxis: {
            name: name + "客户数统计",
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
          },
          legend: {
            orient: "vertical",
            x: "right",
            y: "top",
            data: [s[0].name, s[1].name],
          },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          series: [
            {
              name: s[0].name,
              data: s[0].data,
              type: "line",
              smooth: true, // 让曲线变平滑的
              symbol: "none", // 去掉点
            },
            {
              name: s[1].name,
              data: s[1].data,
              type: "line",
              smooth: true, // 让曲线变平滑的
              symbol: "none", // 去掉点
            },
          ],
        };
        this.sesalChartUserType.setOption(option);
      });
    },
    Get_Sex(start, end) {
      API_Sta[this.funcSex]({
        start,
        end
      }).then((res) => {
        this.loading = false;
        var localName = [];
        const dat = [];
        if (res.length > 0) {
          res.forEach((e, i) => {
            dat.push({
              value: e.sex_sum,
              name: e.vs_type === 1 ? '男' : '女'
            })
          });
          res.forEach((e, i) => {
            localName.push(e.vs_type === 1 ? '男' : '女')
          });
        } else {
          Array.apply(null, {length: 2}).forEach((e, i) => {
            dat.push({
              value: 0,
              name: i === 0 ? '男' : '女'
            })
          });
          Array.apply(null, {length: 2}).forEach((e, i) => {
            localName.push(i === 0 ? '男' : '女')
          });
        }
        const option = {
          title: {
            text: '男女比例',
            left: 'center'
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          legend: {
            orient: "vertical",
            right: 10,
            top: 260,
            bottom: 20,
            // data: ["百度小程序", "支付宝小程序", "PC端", "H5", "微信小程序"],
            data: localName
          },
          series: [
            {
              name: "会员来源",
              type: "pie",
              radius: "55%",
              center: ["50%", "60%"],
              // data: [
              //   { value: 0, name: "百度小程序" },
              //   { value: 0, name: "支付宝小程序" },
              //   { value: 0, name: "PC端" },
              //   { value: 0, name: "H5" },
              //   { value: 0, name: "微信小程序" },
              // ],
              data: dat
            },
          ],
        };
        this.sesalChartSex.setOption(option);
      });
    },
    Get_Area(start, end) {
      API_Sta[this.funcArea]({
        start,
        end
      }).then((res) => {
        this.loading = false;
        const { data, name } = res
        const _data = data.map((item, index) => ({ name: name[index], value: data[index] }))
        this.sesalChartArea.setOption(echartsOptionsMap({
          titleText: '客户分布情况',
          seriesName: this.funcSex === 'getSexPlatform' ? '平台访问客户' : '下单客户',
          seriesData: _data
        }))
      });
    },
    Get_From(start, end) {
      API_Sta.getComeFrom({
        start,
        end
      }).then((res) => {
        this.loading = false;
        const x = res.xAxis
        const s = res.series
        const source = []
        x.forEach((e, i) => {
          source.push([
            e,
            s[0].data[i],
            s[1].data[i],
            s[2].data[i],
          ])
        })
        source.unshift([
          'product',
          s[0].name,
          s[1].name,
          s[2].name,
        ])
        const option = {
          legend: {},
          tooltip: {},
          dataset: {
            source
            // : [
            //   ['product', '2015', '2016', '2017'],
            //   ['Matcha Latte', 43.3, 85.8, 93.7],
            //   ['Milk Tea', 83.1, 73.4, 55.1],
            //   ['Cheese Cocoa', 86.4, 65.2, 82.5],
            //   ['Walnut Brownie', 72.4, 53.9, 39.1]
            // ]
          },
          xAxis: {type: 'category'},
          yAxis: {name: "人数/个"},
          // Declare several bar series, each will be mapped
          // to a column of dataset.source by default.
          series: [
            {type: 'bar'},
            {type: 'bar'},
            {type: 'bar'}
          ]
        }
        this.sesalChartFrom.setOption(option);
      });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.item-row {
  margin-bottom: 10px;
  .el-col {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}
.item_list {
  background: #ffffff;
  border-radius: 12px;
  margin: 0 5px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  img {
    margin-top: 22px;
  }
  div {
    span {
      display: block;
    }
    span:nth-child(1) {
      color: #212121;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 5px;
    }
    span:nth-child(2) {
      font-size: 14px;
      font-weight: bold;
      color: #434343;
    }
  }
  img {
    width: 82px;
    height: 82px;
  }
}
.echart-title::after {
  display: none;
}
.echart-title {
  height: 50px;
  font-size: 18px;
  color: #007f7f;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p:nth-child(2) {
    color: #212121;
    font-size: 14px;
  }
  p span {
    cursor: pointer;
    line-height: 20px;
    height: 24px;
    display: inline-table;
    margin: 0 5px;
  }

  p span.active {
    color: #007f7f;
    border-bottom: 2px solid #007f7f;
  }
}

.echart-alltitle {
  display: flex;
  justify-content: space-around;
}
.echarts-left1 {
  width: 15%;
}
.echarts-left {
  height: 100px;
  width: 100%;
  background: rgba(51, 205, 189, 0.08);
  border-radius: 8px;
  margin-top: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  h2 {
    font-size: 30px;
    font-weight: 400;
    line-height: 0px;
    color: #212121;
  }
  p {
    font-size: 15px;
    line-height: 0px;
    font-weight: 500;
    margin-top: -10px;
    color: #656565;
  }
}
.echarts-left:nth-child(2),
.echarts-left:nth-child(3) {
  background: rgba(253, 85, 87, 0.08);
}
.echarts-right {
  width: 100%;
  height: 550px;
}

.elcard {
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    background: #f3f5f7;
    border-radius: 8px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #212121;
    margin-bottom: 8px;
    padding: 0 5px;
    cursor: pointer;
  }
}

.dashboard-container {
  height: 100%;
  ::v-deep .el-card__body {
    padding: 10px;
  }
  .goods-statistics {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    a {
      display: block;
      margin: 0;
      width: 75%;
      button {
        width: 100%;
      }
    }
  }
  .goods-image {
    width: 50px;
    height: 50px;
  }
}
.el-row {
  position: relative;
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
</style>
